import React from "react";
// import { FaPlay, FaTwitter, FaInstagram, FaYoutube, FaFacebook, FaLinkedin } from "react-icons/fa";
import styles from "../css/footer.module.css";

import playstoreIcon from '../assets/playstore.png';
import twitterIcon from '../assets/twitter.png';
import facebookIcon from '../assets/facebook.png';
import youtubeIcon from '../assets/youtube.png';
import linkedinIcon from '../assets/linkedin.png';
import instagramIcon from '../assets/instagram.png';
import { FaWhatsapp } from 'react-icons/fa';


const Footer = () => {

  const openPlayStore = () => {
    window.open("https://play.google.com/store/apps/details?id=antaragni.flame", "_blank"); // Opens Play Store in a new tab
  };

  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <img onClick={openPlayStore} src={playstoreIcon} alt="Play Store" className={styles.icon} />
        <span onClick={openPlayStore} className={styles.getApp}>Get the App</span>
      </div>
      <div className={styles.socialIcons}>
        <a href="https://x.com/antaragni" target="_blank" rel="noopener noreferrer" className={styles.socialIcons}>
          <FaWhatsapp className={styles.icon} />
        </a>
        <a href="https://x.com/antaragni" target="_blank" rel="noopener noreferrer" className={styles.socialIcons}>
          <img src={twitterIcon} alt="Twitter" className={styles.icon} />
        </a>
        <a href="https://www.instagram.com/antaragni.iitkanpur/?hl=en" target="_blank" rel="noopener noreferrer" className={styles.socialIcons}>
          <img src={instagramIcon} alt="Instagram" className={styles.icon} />
        </a>
        <a href="https://www.youtube.com/@antaragniiitkanpur" target="_blank" rel="noopener noreferrer" className={styles.socialIcons}>
          <img src={youtubeIcon} alt="YouTube" className={styles.icon} />
        </a>
        <a href="https://www.facebook.com/antaragni.iitk/" target="_blank" rel="noopener noreferrer" className={styles.socialIcons}>
          <img src={facebookIcon} alt="Facebook" className={styles.icon} />
        </a>
        <a href="https://www.linkedin.com/company/antaragni-iit-kanpur/mycompany/" target="_blank" rel="noopener noreferrer" className={styles.socialIcons} >
          <img src={linkedinIcon} alt="LinkedIn" className={styles.icon} />
        </a>
      </div>
      <div className={styles.right}>
        <span>© Antaragni’24</span><span className={styles.iitk}>, IIT Kanpur</span>
      </div>
    </div>
  );
};

export default Footer;


