import styles from "../css/login.module.css";
import { firebaseGoogleSignIn } from "../config/firebaseAuth";
import { Link } from "react-router-dom";

const LogIn = () => {
  return (
    <div className={styles.login}>
      <div className={styles.cover}>
        <div className={styles.homebtn}>
          <Link to="/" className={styles.link}>
              Home
            </Link></div>
            <div className={styles.loginbox}>
        <h2 className={styles.logintitle}> Hello!<br />Welcome Back</h2>
        <p className={styles.logintitle2}>Log in to our Events page and unlock a world of <br /> thrilling competitions—join the excitement today!</p>
        
          <button className={styles.glogin}
            onClick={() => {
              firebaseGoogleSignIn();
            }}>Login With Google
          </button>
          <br />
        
        </div>
      </div>
    </div>
  );
};

export default LogIn;
